/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Required CSS for Angular Calendar Module */
@import "../node_modules/@beego/ngx-calendar/assets/calendar.css";
@import "./app/modules/internal/gui/pages/calendar-page/desktop/calendar.desktop.page.scss";
@import "./app/modules/internal/gui/pages/calendar-page/mobile/calendar.mobile.page.scss";

/* Required CSS for select component & date picker */
@import "../node_modules/@beego/ngx-form-components/assets/form-components.css";
@import "./app/modules/internal/gui/components/event-components/event-component/event.component.scss";

/* Required CSS for editor component */
@import "./app/modules/internal/gui/pages/tenant-page.ts/desktop/tenant.desktop.page.scss";
@import "./app/modules/internal/gui/pages/communication-template-page/desktop/communication-template.desktop.page.scss";

/* Global style */
@import "src/theme/variables.scss";
@import url(https://fonts.googleapis.com/css?family=Open+Sans);

html, body, table, * {
    font-family: 'Open Sans',serif !important;
    font-size:14px;
}

html, body {
    background-color: white !important;
}

html {
	padding-top:  constant(safe-area-inset-top) !important;
	padding-top: env(safe-area-inset-top) !important;
	padding-bottom: calc(constant(safe-area-inset-bottom)) !important;	
	padding-bottom: calc(env(safe-area-inset-bottom)) !important;	
}

body {
	position: relative;
}

._18px {
    font-size: 18px !important;
}

._nobr{
    white-space: nowrap;
    font-size: inherit;
    font-weight: inherit;
    line-height:inherit;
}


._title {
    font-size: 20px;
    font-weight: 500;
    color: $dark-black;
    margin: 0;
}

._small-title {
    font-size: 17px;
    color: $dark-black;
    margin: 0;
}

._subtitle {
    font-size: 13px;
    color: grey;
    margin: 0;
}

info-line{
    ._info-line{
        margin-bottom:15px;
        p{
            font-size:14px;
        }
    }

    >:last-of-type{
        ._info-line{
            margin-bottom:0px;
        }
    }
}


._button {
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: 3px 3px 10px lightgrey;
    text-align: center;
    display: flex;
    flex-direction: row;
	align-items: center;
	justify-content: flex-start;
    gap:8px;
	font-size: 15px;
	background-color: lightgrey;
	color: black;

	&:active {
		opacity: 0.9;
		box-shadow: none;
	}

    &:hover {
        cursor: pointer;
	}
	
    &[disabled] {
       opacity:0.5;

        &:hover {
            cursor: not-allowed;
        }
    }

    ion-icon {
		color: black;
		font-size: 20px;
		flex-shrink: 0;
    }

    span {
		margin-left: 10px;
		margin-right: 15px;
		flex-shrink: 10;
		color: black;
        text-align:center;
    }

    &._delete-button {
        background-color: $red;
        color:white;

        span,
        ion-icon {
            color: white !important;
        }
    }

    &._orange-button
    {
        background-color: $orange !important;
        color:white !important;
        span,
        ion-icon{
            color:white !important;
        }
    }

    &._export-button,
    &._save-button,
    &._edit-button,
    &._add-button,
    &._create-button,
    &._loan-button,
    &._return-button,
    &._upload-button,
    &._change-button,
    &._print-button,
    &._search-button
    {
        background-color: $green !important;
        color:white !important;
        span,
        ion-icon{
            color:white !important;
        }
    }

    &._neutral-button,
    &._back-button,
    &._cancel-button,
    &._refresh-button,
    &._info-button,
    &._filter-button
    {
        background-color:white !important;
        border:1px solid $separator-grey !important;
        color:black !important;
        span,
        ion-icon{
           color:black !important;
        }
    }

    &._inverse{
        background-color:white !important;
        color: $green !important;
        border:2px solid $green;
        span,
        ion-icon{
            color:#468730 !important;
         }
    }

    &._red-inverse{
        background-color:white !important;
        color: $red !important;
        border: 2px solid $red;
        span,
        ion-icon{
            color: $red !important;
        }
    }
}

._badge {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: $red;
    position: absolute;
    top: -5px;
    right: -5px;
    font-size: 13px;

    ._count {
        color: whitesmoke;
    }

}


quill-editor {
    .ql-toolbar {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        .ql-snow .ql-picker.ql-size .ql-picker-label[data-value]:not(&:first-child)::before,
        .ql-snow .ql-picker.ql-size .ql-picker-item[data-value]:not(&:first-child)::before {
            content: attr(data-value) !important;
        }
    }
    .ql-container {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .ql-editor {
        // the same value as the line-height in the emails
        line-height: 23px; 
    }

    variable._variable {
        padding: 1px 5px;
        background-color: #F1F1F1;
        border-radius: 15px;
    }
}

form-input {
    input:read-only {
        color: grey !important;

        &:hover {
            cursor: not-allowed;
        }
    }
}

form-textarea {
    input:read-only {
        color: grey !important;

        &:hover {
            cursor: not-allowed;
        }
    }
}

form-select {
    ._button {
        background-color: $green;
        padding: 6px;
        margin-left: 10px;

        ion-icon {
            margin-right: 0px;
            font-size: 24px;
            color: black;
        }
    }

    &._disabled{
        opacity:.5;
        pointer-events: none;
    }

    ng-select{
        width:100%;
        min-width:0px; // avoids this item getting wider then the flex container

        .ng-select-container{
            width:100%;

            .ng-value-container{
                width:100%;
                max-width:95%;
                flex-direction:column;
                align-items:start !important;
                padding:4px !important;
                position: relative !important;
    
                .ng-value{
                    width:100%;
                    margin-bottom:4px !important;
                    border-radius: 20px !important;
                    padding: 3px !important;
                    display: flex;
    
                    .ng-value-icon{
                        border-right:0px !important;
                        padding: 0px 6px !important;
                        border-radius:20px !important;
    
                        &:hover{
                            color:white !important;
                        }
                    }
    
                    .ng-value-label{
                        text-overflow: ellipsis;
                        overflow:hidden;
                    }
                }
    
                .ng-input{
                    position:relative !important;
                    background-color: #e0e0e0;
                    border-radius: 20px;
                    flex-grow: 1;
                    width: 100%;
                    padding:0px !important;
                    display: none;
                    top:0px;
    
                    input{
                        padding: 4px 10px !important;
                        position: relative;
                        background-image:url('assets/icons/search-outline.svg') !important;
                        background-repeat: no-repeat !important;
                        background-position: 95% center !important;
                        background-size: 22px 22px !important;
                        box-sizing: border-box !important;
                    }
                }
            }
        }



        &.ng-select-opened{
            .ng-value-container{
                .ng-input{
                    display:block;
                }
            }
        }

        &.ng-select-single{
            .ng-select-container{
                height:auto !important;
            }
            .ng-value-container{
                .ng-value{
                    background-color:transparent !important;
                    margin-bottom:0px !important;
                }
            }
        }

        .ng-clear-wrapper{
            display:none;
        }

        ng-dropdown-panel{
            border-radius:$border-radius !important;

            .ng-option{
                background:transparent !important;
            }
        }
    }
}


form._directive-disabled-form {
    input[disabled],
    textarea[disabled] {
        color: inherit !important;

        &:hover {
            cursor: default;
        }
    }
}



* {
    outline: none;

    &:focus {
        outline: none;
    }
}

@media only screen and (min-width: 1024px) {
    // adds spacer to scroll on desktop page (add pages that need it)
    profile-page {
        margin-top: 20px;
    }
}

._navigatable, ._link {
    cursor: pointer;

	&:hover {
		opacity: 0.7;
	}
}

filter-component {
	.ng-select  {
		max-width: inherit;

        .ng-arrow-wrapper {
            margin-left: 10px;
        }
		
		.ng-dropdown-panel {
			width: auto;
		}
	}
}

table-desktop-component{
    ._refresh-button {
        background-color: white !important;
        border: 1px solid #dedede !important;
    
        ion-icon {
            color: black !important;
        }
    }

    ion-row[data] {
        min-height: 79px;
        width: 100%;
        padding: 20px;
        margin-bottom: 10px;
        border-radius: $border-radius;
		background-color: white;

        ion-col{
            padding:0px;
        }
    }

    ion-row{
        ion-col{
            padding-right:5px !important;
        }
    }

    table-col{
        padding:0px 5px;

        > ion-col{
            display: block;
            max-width: none !important;
            width: 100% !important;
        }

        &:first-child{
            padding-left:0px;
        }

        &:last-child{
            padding-right:0px;
        }        
    }

    table-col._center{
        ion-col{
            button{
                margin:0 auto;
                position:relative;
            }
            div{
                text-align:center;
            }
        }
    }

    ._head{

        ._row{
            flex-wrap:wrap;

            > * {
                margin-bottom:10px;
            }
        }

        ._button{
            margin-right:10px;
        }
    }
}

._tooltip._calendar {
    img {
        display: none;
    }
}

/* Print rules */

@media print {
	body.backdrop-no-scroll {
		overflow: visible;
	}
}

/* Ionic component overrides */

ion-select:not(.legacy-select) {
    min-height: inherit;
}

ion-content {
    --ion-background-color: transparent;
}

ion-popover {
	--width: fit-content !important;
    background-color:rgba(0,0,0,.35);

    &::part(content) {
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }

}

ion-loading._loading-indicator {
	--background: #F1F1F1;
	--spinner-color: #4B4237;
	color: #4B4237;

    ion-backdrop {
        background-color: transparent !important;
    }

	.loading-wrapper {
		border-radius: 10px;

		.loading-content {
			font-size: 16px;
		}

	}

}

ion-searchbar {

    .searchbar-input-container  {
        border: 2px solid #f0f0f0;
        border-radius: $border-radius;
        --box-shadow: none;

        input {
            border-radius: $border-radius !important;
        }
    }

} 


ion-modal {

    --background:  transparent !important;
    --overflow: visible; // reveal the close button

    @media only screen and (min-width: 768px){
        --width: fit-content !important;
    }

    @media only screen and (min-width: 1024px) {
        --border-radius: 7px !important;
    }

    &.auto-height {
        
        &.bottom {
            align-items: flex-end;
        }
    
		--height: auto;

		@media print {
			--height: 100%;
		}
        
        @media only screen and (max-width: 1024px) {
            --height: 100%;
        }
    
        .ion-page {
            position: relative;
            display: block;
			height: 100%;
            overflow: visible; // reveal the close button
    
            .inner-content {
                max-height: 90vh;
                overflow: visible; // reveal the close button
				
				@media only screen and (max-width: 1024px) {
					max-height: 100%;
					height: 100%;
				}

				@media print {
					overflow: visible;
				}
            }
        }
    }

    modal-component{

        &._wide{
            ._modal-component-wrapper{
                width:calc(100vw - 120px);
            }
        }

        ._left{
            ._left-scroll{
                ._header{
                    p {
                        margin: 0;
                    
                        &._title, &._title span {
                            font-weight: bold;
                            font-size: 25px;
                            color: $dark-black;
                            margin: 0 0 5px;
    
                            ._ended{
                                color:$orange;
                                font-size: 18px;
                                font-weight: normal;            
                            }
                        }
                    
                        &._subtitle {
                            color: grey;
                            font-size:18px;
                            margin-bottom:20px;
                        }
                        &._ended{
                            color:$orange !important;
                            font-size: 18px;
                            font-weight: normal;            
                        }
                    }	
                }
                ._info{
    
                    margin-top: 20px;
    
                    ._info-line-no-icon{
                        margin-bottom:20px;
                    }
    
                    ._buttons{
                        display:flex;
                        gap:10px;
                        flex-direction: row;
                    }                
                }
            }
        }

        ._right{  
            ._right-scroll{
                padding:20px;
    
                form-select,
                form-input,
                form-textarea,
                form-number,
                form-date,
                form-radio,
                form-time{
                    display: block;
                    margin-bottom:20px;
                }

                ._formcontrol-with-validation{
                    > * {
                        margin-bottom:0px;
                    }
                
                    margin-bottom:20px;
                }                

                ._title{
                    margin-bottom:15px;
                    padding-bottom:5px;
                    border-bottom:1px solid $separator-grey;
                }
                ._small-title{
                    margin-top:0px;
                    margin-bottom:10px;
                }

                ._data{
                    margin-bottom:20px !important;

                    form-select,
                    form-input,
                    form-textarea,
                    form-date,
                    form-radio,
                    form-time{
                        margin-bottom:0px !important;
                    }
                }

                ._checkbox-container{
                    display:flex;
                    gap:10px;
                    margin-bottom:20px;
                }
            }
            ._buttons{
                ._save-button {
                    background-color: $green;
                }
        
                ._delete-button {
                    background-color: $red;
                    margin-right: 10px;
                    color: white;
                }
            }
        } 
    }
}






// Applies a text wrap on ion options
.alert-tappable.alert-radio,
.alert-tappable.alert-checkbox {
    height: auto;
    contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios,
.alert-checkbox-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-ios {
    white-space: normal;
}

ion-alert {
	._alert-button {
		color: black;
	}
}

ion-toast {

    &._saved-toast {
        --background: #78BC61;
        --border-radius: 10px;
        --width: fit-content;
        --color: black;
		--ion-safe-area-bottom: 20px;
		--max-width: 90%;
        --button-color: white;
        font-weight: 500 !important;
	}
	
	&._error-toast {
        --background: #EB4B24;
        --border-radius: 10px;
        --width: fit-content;
        --color: white;
		--ion-safe-area-bottom: 20px;
		--max-width: 90%;
		--button-color: white;
		font-weight: 500 !important;
		
		.toast-wrapper {
			max-width: 90%;
		}

    }

}

.plt-mobile.plt-mobileweb ion-toast {
	
	&._saved-toast {
		--max-width: 90%;
	}
	
	&._error-toast {
		--max-width: 90%;
    }
}

ion-grid {
	--ion-grid-columns: 15;
}

ion-select{
    padding:0px;
    padding-bottom:10px;
    --highlight-color: inherit;
}





public-events-mobile-page ion-content::part(scroll){
    position: static;
    background-color: #ececec;
}

._info{
    padding:15px;
    background-color:#f1f1f1;
    border-radius:7px;
    &._actionable {
        background-color: #FFFBE9;
    }

    &._error {
        background-color:#fad9d4;
    }



    * {
        color:gray;
        font-size:14px;
    }

    > *:first-child{
        margin-top:0px;
    }

    &._new-event{
        > div{
            padding-left:50px;
            position: relative;
    
            ion-icon{
                position:absolute;
                left:0px;
                top:0px;
                width:40px;
                height:40px;
            }
            
            p{
                margin-top:0px;
            }
        } 
    }
}

._segment-desktop-component{
    ._segment-container{
        justify-content: center;
        width: auto !important;

        ._segment{
            align-self:stretch;
            display: flex;
            align-items: center;

            ._segment-name{
                position: relative;
            }

            border:2px solid white;

            &._active{
                border:2px solid $orange;

                p{
                    padding-bottom:0px !important;
                    border-bottom:0px !important;
                }
            }
        }
    }
}

._segmented-component {
    margin-top: 65px !important;

	table-desktop-component ._head {
		top: 92px !important;
		padding-top: 0 !important;
		margin-top: 0 !important;

        ._header_buttons_wrapper{
            display: flex;
        }
	}

    table-desktop-component ._head._embedded {
        top: 0 !important;
        padding-top: 20px !important;
    }

    > ._info{
        background-color: white;
        padding: 20px;
        border-radius: $border-radius;
        text-align: center;
        margin-bottom: 20px;
        color:$black;
    }
}

._wrap{
    ._table-desktop-component{
        ._row._filters{
            &:after{
                content:"";
                width:100%;
                height:1px;
                order:1;
            }
    
            select-table-filter-component,
            input-table-filter-component,
            date-table-filter-component{
                order:2;
            }
    
            select-table-filter-component{
                ng-select{
                    margin: 5px 0px 5px 10px;
                    min-width:220px;
                }
            }
        }
    }
}




// Mobile view
@media screen and (max-width:768px){

    html,
    body,
    table,
    *{
        font-size:15px;
    }
    p,
    span,
    a,
    b,
    i
    {
        font-size:15px;
    }
    ._info *,
    info-line ._info-line p{
        font-size:15px;
    }
    ._buttons ._button{
        padding:15px;
        border-radius:15px;
        font-size:15px;
    }


    events-list-mobile-page{
        table-mobile-component {
            display:flex;

            ._table-mobile-component{
                flex-grow: 1;
                flex-basis: 0;
                height:auto !important;
            }

            ._filter-order-bar-container{
                display: none;
            }
        }

        ion-card{
            background:white;

            ion-card-content.card-content-ios,
            ion-card-content.card-content-md{
                display: flex;
                flex-direction: column;
                gap:10px;

                ._date{
                    display: inline-block;
                }
                ._date:first-letter{
                    text-transform: uppercase;
                }
    

                h2{
                    font-size:20px;
                }
            }
        }
    
        input-filter-component,
        select-filter-component{
            ._filter-component{
                width:100% !important;
                flex-direction:column !important;
                align-items:start !important;

                padding:0px !important;
                border:0px !important;

                ._filter-name{
                    text-transform: none !important;
                }

                ._selected{
                    .ng-select-container{
                        margin-left:0px !important;
                    }
                    ng-dropdown-panel{
                        margin-left:0px !important;
                        width:100% !important;
                        max-height:150px !important;

                        .ng-dropdown-panel-items{
                            max-height:150px !important;
                        }
                    }                    
                }

                input{
                    margin:0px !important;
                }
            }
        }
    }
}



// Public events page: form-search-address diverges from regular look
body{
    ._public-events-page{
        ._events{
            transition:opacity .2s;
        }
    }
    &.backdrop-no-scroll{
        ._public-events-page{
            ._events{
                opacity:0.2;
            }
        }        
    }
}

._public-events-page{
    
    ._selectedLocation{
        background-image:url('./assets/imgs/marker.png');
    }

    form-search-address{
        .input{
            border-radius:0 0 20px 20px;
            background-color:white;
            border:1px solid grey;
            border-top:0px;
            padding:20px 20px 30px 20px;
            -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.1); 
            box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.1);

            input{
                background-color:#e9e9e9;
            }

            ion-icon{
                top: 45px !important;
                left: 30px !important;
            }
        }

        ._search-results{
            width:calc(100% - 40px) !important;
        }
    }

    .leaflet-top{
        z-index:400;
    }

    .leaflet-tooltip{
        ._title_wrapper{
            h2{
                font-size:14px;
                margin:0px;
                font-weight:bold;
            }
            h3{
                font-size:14px;
                margin:0px;
            }
        }
    }

    .leaflet-popup{        
        .leaflet-popup-content-wrapper{
            padding:0px;

            .leaflet-popup-content{
                margin:0px;

                ._title_wrapper{
                    background-image:url('./assets/imgs/marker.png');
                    background-size: 50px;
                    background-position: 10px 20px;
                    background-repeat: no-repeat;
                    border-bottom: 1px solid #c5c5c5;
                    // margin-bottom: 10px;
                    padding: 20px 40px 20px 70px;

                    // background-color: #ececec;
                    // border-radius: 10px 10px 0 0;

                    h2{
                        margin:0px !important;
                        padding:0px !important;
                        font-weight: bold;
                    }
                    h3{
                        margin:0px !important;
                        padding:0px !important;
                        font-size:14px;
                    }
                }

                ._content_wrapper{
                    padding-bottom:10px;

                    ._event{
                        padding: 10px 20px;
                        a{
                            color: #7e7e7e;
                            text-decoration: none;
                            border-bottom: 1px solid #e5e5e5;

                            transition: padding-left 0.25s;

                            &:hover{
                                padding-left:4px;
                            }
                        }
                    }
                }
            }
        }
        .leaflet-popup-tip-container{

        }
        .leaflet-popup-close-button{
            top: 6px;
            right: 6px;

            span{
                font-size: 20px;
                // color: white;
                font-weight: bold;
                // background-color: #808080;
                //  border-radius: 20px;
                display: block;
            }

            &:hover{
                opacity:0.8;
            }
        }
    }
}




@media screen and (max-width:767px){

    ion-app{
        > ion-router-outlet{          
            > *:not(public-events-page, landing-page, login-page, reset-page, internal-component, register-tenant-page){
                background-color: #313131 !important;

                &:after{
                    content:"Gebruik aub. een toestel waarvan het scherm minstens 768px breed is. Gebruik je een tablet? Hou het toestel dan zeker horizontaal.";
                    position:absolute;
                    left:50px;
                    right:50px;
                    top:49%;
                    text-align:center;
                    display:block;
                    color:white;
                }

                > *{
                    display:none !important;
                }
            }
        }
    }
}


@media screen and (max-width:1350px){
    table-desktop-component{
        table-col{
            button{
                span{
                    display: block;
                    max-width:100px; // force line break for equal height buttons
                }
            }
        }
    }
}

segment-desktop-component{
    ._segment-container{
        ._segment{
            min-height: 45px;
        }
    }
}


@media screen and (max-width:1400px) {
    segment-desktop-component{
        width:auto;
        left:0px;
        right:0px;
        padding:20px !important;

        ._segment-container{
            margin:0px !important;
            align-items: flex-start !important;
            width:100% !important;
            gap:10px;
            justify-content: center;

            ._segment{
                margin-left: 0px !important;
                margin-right: 0px !important;
                padding: 10px 6px !important;
                border-radius: 10px !important;

                p{
                    text-align:center;
                    font-size:14px;
                }
            }
        }
    }   
}

@media screen and (max-width:1200px){
    ._calendar-desktop-page{
        ._left-calendar-headers{
            ._navigation-buttons{
                margin-right:10px !important;
                ._navigation-button{
                    padding:5px !important;
                }
            }
        }
    }

    segment-desktop-component{

        ._segment-container{

            ._segment{

                p{
                    font-size:12px;
                }
            }
        }
    }    
}



@media screen and (max-width:1180px){    

    ._question{
        flex-direction:column;
        align-items:start !important;
        ._label{
        }
    }

    
    button:not(._refresh-button,._filter-button, ._loan-button, ._info-button, ._add-button){
        ion-icon{
            display: none;
        }
    }
    devices-desktop-page{
        button{
            ion-icon{
                display: block;
            }
        }
        button:not(._refresh-button,._filter-button){
            ion-icon{
                display: none;
            }
            span{
                margin:0px;
            }
        }
    }

    clients-desktop-page{
        button{
            ion-icon{
                display: block;
            }
        }
        button:not(._info-button, ._refresh-button, ._filter-button){
            ion-icon{
                display: none;
            }
            span{
                margin:0px;
            }
        }
    }

    navigation-desktop-component{
        ._navigation-desktop-component{
            padding:0px 5px !important;
        }

        ._description-toggle-container{
            display: none;
        }
        ._navigation-button-description{
            display:none;
        }
        ._logo-container,
        ._environment
        {
            display:none !important;
        }
        ._user-container{
            padding: 20px 0px !important;
            ion-icon{
                display:none;
            }
            ._username{
                display:none;
            }
            p,
            span
            {
                margin:0px !important;
                font-size:10px !important;
            }
        } 
    }

    tenant-desktop-page{
        ._upload-container {
            height:auto !important;
        }
    }

    ._calendar-desktop-page{
        padding:10px 5px 5px 5px !important;
        gap:5px !important;

        ._left-calendar-headers{
            ._navigation-buttons{
                margin-right:5px;

                ._navigation-button{
                    padding:5px !important;
                }
            }
            ._view-selection{
                margin-right:5px !important;
            }
            ._current-daterange{
                width: 60px !important;
                b{
                    font-size:10px;
                    line-height: 12px;
                    display: inline-block;
                    font-weight: normal;
                }
            }
        }

        ._right-calendar-headers{
            top:10px !important;
            right:5px !important;

            ._add-button{
                ion-icon{
                    display: block !important;
                }
                span{
                    display: none !important;
                }
            }
        }

        ._filters{
            ._filters-container{
                padding:10px !important;
            }
        }

        calendar-desktop-component{
            ._calendar{

                *{
                    font-size:10px !important;
                }

                .cal-event{
                    span{
                        line-height:1em;
                    }
                }

                mwl-calendar-day-view{
                    mwl-calendar-week-view{
                        .cal-week-view{
                            .cal-time-events{
                                .cal-day-columns{
                                    .cal-day-column{
                                        .cal-hour{
                                            mwl-calendar-week-view-hour-segment{
                                                .cal-hour-segment{
                                                    .cal-time{
                                                        font-size:10px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                mwl-calendar-month-view,
                mwl-calendar-week-view{
                    .cal-month-view,
                    .cal-week-view{
                        mwl-calendar-week-view-header,
                        mwl-calendar-month-view-header{
                            .cal-cell{
                                font-size:10px;
                            }
                            .cal-day-headers{
                                margin-left:40px;
                                .cal-header{
                                    padding-left:0px;
                                    padding-right:0px;
                                    b,span{
                                        font-size:12px;
                                    }
                                    b{
                                        font-size:10px;
                                        padding:0px 5px;
                                    }
                                }
                            }
                        }
                        .cal-time-events{

                            .cal-time-label-column{
                                width:40px;
                                .cal-hour{
                                    mwl-calendar-week-view-hour-segment{
                                        .cal-hour-segment{
                                            
                                            .cal-time{
                                                width:40px;
                                                font-size:10px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }    
}