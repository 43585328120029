@import "src/theme/variables.scss";

._calendar-desktop-page {
    padding: 20px;
	position: relative;
	display:flex;
	gap:0px; // collapsed filters

    ._right-calendar-headers {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: absolute;
		top:20px;
		right:20px;

		._button{
			box-shadow:none;
		}

		&._has-filters {
			top: 75px;
		}

		._add-button {
			margin-right: 10px;

			ion-icon {
				font-weight: bold;
			}

			span{
				margin-left:0px;
				margin-right:0px;
			}
		} 
	}

	._left-calendar-headers{
		._current-daterange{
			margin:0px;
		}
	}

	._filters {
		position:relative;
		display: flex;
		flex-direction:column;
		height: 100%;
		align-items:flex-start;
		z-index:1;
		width:0px; // collapsed filters

		> * {
			display: block;
			margin-bottom: 30px;
		}		

		._filter-button{
			position: relative;
			display: flex;
			box-shadow:none;
			margin-bottom:0px;
			white-space: nowrap;

			ion-icon._filtering{
				position: absolute;
				background-color: $green;
				color:white;
				border-radius:20px;

				right: -9px;
				top: -9px;
				padding: 2px;
				width: 16px;
				height: 16px;
			}
		}

		._filters-container{
			width:100%;
			position: relative;			
			height: calc(100vh - 90px);
			overflow-y:auto;
			padding: 20px;
			background-color: white;
			border:1px solid #dedede;
			border-radius:15px;	
			margin-top:10px;
			flex-grow:1;
			margin-bottom:0px;
			
			display:none; // collapsed filters

			flex-direction:column;

			calendar-select-filter-component{
				width:100%;
				position: relative;
			}

			._spacer{
				display: block;
				flex-grow:1;
			}

			._info{
				margin-top:20px;
				display: flex;
				gap:10px;

				p{
					margin:0px;
				}
			}

			._warning{
				background-color: rgba(235, 75, 36, 0.2);
			}

			._message {
				background-color: rgba(120, 188, 97, 0.2);
			}

		}
	}
	
	calendar-desktop-component{
		flex-grow:1;
		
		._base-calendar-desktop-component{
			._calendar-headers{
				padding-left: 180px; // collapsed filters

				._left-calendar-headers{
					._navigation-buttons{
						._navigation-button{
							padding:5px 10px !important;
						}
					}

					._view-selection{

					}

					._navigate-to-date{
						._filter-component{
							// border:1px solid red;
							padding:0px;

							._filter-name{
								display: none;

								@media screen and (min-width:1380px){
									display:block;
									text-transform:none;
									margin-left:15px;
								}
							}

							._selected{
								._date-input{
									text-align:center;
									width:8em;
									margin:0px;
									background:transparent;
									border:none;
								}
							}
						}
					}
				}
			}

			._calendar {
				height: calc(100vh - 90px);
				

				mwl-calendar-week-view,
				mwl-calendar-month-view{
					.cal-week-view,
					.cal-month-view{
						display: flex;
						flex-direction: column;

						box-shadow:none;
						border:1px solid #dedede;
						height:100%;
						position: relative;

						.cal-time-events{
						}

						.cal-days{
							display: flex;
							flex-direction: column;

							flex-grow:1;

							> .ng-star-inserted{
								flex-grow:1;
								position: relative;

								.cal-cell-row{
									height: 100%;
								}
							}
						}
					}
					.cal-month-view{
						overflow-y: auto;
					}					
				}
			}			
		}

		._calendar-filters {
			margin-bottom: 10px;
			display: flex;
			flex-direction: row;
	
			._data-filter-component {
				height: auto;
			}
	
			._filter-name {
				color: grey;
				margin: 5px 0;
				text-transform: uppercase;
			}
	
			._selected ._input {
				padding: 0 10px;
			}
			
		}
	}



	&._expand-filters{
		gap:10px;

		._filters {
			width:300px;
			._filters-container{
				// display:block;
				display: flex;;
			}
		}

		calendar-desktop-component{
			._calendar-headers{
				padding-left:0px;
			}
		}
	}
}
