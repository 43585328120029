@import "src/theme/variables.scss";

._communication-template-desktop-page {
	padding: 20px;

	._container {
		padding: 20px;
		background-color: white;
		box-shadow: $box-shadow-light;
		border-radius: $border-radius;
		margin-bottom: 20px;
		height: calc(100vh - 150px);
		display: flex;
		flex-direction: column;
		padding:0px;

		._grouping-disclaimer {
			padding: 20px;
			border-radius: $border-radius;
			background-color: rgba(237, 177, 66, 0.2);
			margin: 20px;
			margin-bottom: 0;

			p {
				margin: 0;
			}
		}

		._custom-communication-template-inputs-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			flex-wrap: wrap;
			gap: 20px;
			padding: 20px;
			border-bottom: 1px solid #dedede;
			margin-bottom: 20px;
			height: fit-content;

			form-input,
			form-select {
				display: block;
			}

			form-input {
				width: 100%;
			}

			form-select {
				width: calc(50% - 10px);
			}

		}

		._communication-template-container{
			display: flex;
			flex-direction: column;
			overflow-y:auto;
			padding:20px;
			flex-grow: 1;

			._editor-container{
				editor-component {
					display: block;
					flex-grow: 10;
					height:auto;
		
					.ql-container {
						height:auto;
					}
				}
			}
		}

		._buttons {
			width: 100%;
			margin-top: 0px;
			padding:20px;
			display: flex;
			justify-content: flex-end;
			border-top:1px solid #dedede;

			._edit-button {
				background-color: $orange !important;
				margin-right: 20px;
			}

			._delete-button {
				margin-right: 20px;
			}
		}
	}

}