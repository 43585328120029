@import "src/theme/variables.scss";

._calendar-mobile-page {
	padding: 20px;
	padding-bottom: 0;

	._current-daterange {
		text-align: center;
		width: 100%;
		padding: 10px;
		background-color: white;
		border-radius: $border-radius;
		border: 1px solid #D3D3D3;
		margin-top: 0;
		margin-bottom: 5px;
    }

    ._calendar-headers {
        display: flex;
        flex-direction: row;
        align-items: center;
		
		._fab {
			margin-right: 20px;
			margin-bottom: 20px;
		}

		._refresh-button {
			background-color: $orange;
		}

    }

}
