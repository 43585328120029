@import "src/theme/variables.scss";

._tenant-desktop-page {
	padding: 20px;

	._segmented-component {
		margin-top: 85px !important;

		._container {
			margin-top: 20px;
			margin-bottom: 20px;
			background-color: white;
			padding: 20px;
			border-radius: $border-radius;
			box-shadow: $box-shadow-light;

			height: calc(100vh - 130px);
			display: flex;
			flex-direction: column;
			padding:0px;
	
			._title {
				margin-bottom: 20px;

				&:not(:nth-child(1)) {
					margin-top: 20px;
				}
			}
		
			._small-title {
				margin: 20px 0 10px 0;
			}
		
			._small-sub-title {
				font-size: 15px;
			}
		
			._data-container {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				position: relative;
				margin: 0px;
		
				&._nested-container {
					margin-top: 0;
				}
		
				._data {
					width: 33.33%;
					box-sizing:border-box;
					padding-right:10px;
					margin-bottom:10px;
		
					@media screen and (max-width: 1280px) {
						width: 50%;
					}
		
					@media screen and (max-width: 1024px) {
						width: 100%;
					}
		
					&._full {
						width: 100%;
					}
		
					&._half {
						width: 50%;
		
						@media screen and (max-width: 1024px) {
							width: 100%;
						}
					}
				}
	
				&._images-container {
					margin-bottom: 40px;
				}
	
				._upload-container {
					background: #e8f2e2;
					padding: 15px;
					padding-bottom: 0;
					border-radius: 7px;
					border: 1px solid #dde9d6;
					text-align:left;
					height: 100%;
	
					input{
						cursor:pointer;
					}
	
					._image-container {
						margin-top: 20px;
						
						img {
							max-width: 200px;
						}
					}
				}

				editor-component {
					width: 100%;
				}

			}
	
			._buttons {
				width: 100%;
				display: flex;
				justify-content: flex-end;
				padding: 30px 10px 10px 10px;
			}

			&._tenant-details-container{
				padding:20px;
				overflow:auto;
			}

			&._communication-templates-container {
				max-height: calc(100vh - 130px);
				overflow-y: auto;
				padding: 20px;

				._title {
					margin-bottom: 0;
				}

				._subtitle {
					margin-bottom: 20px;
				}

				._communication-template-disclaimer {
					padding: 20px;
					border-radius: $border-radius;
					background-color: rgba(237, 177, 66, 0.2);
					margin-bottom: 20px;
		
					p {
						margin: 0;
					}

					._disclaimer-title {
						margin-bottom: 10px;
						font-weight: bold;
					}
				}

				._communication-template-group {
					padding: 20px 20px 40px 0;
					border-bottom: 2px solid #D1D1D1;
					margin-bottom: 20px;

					&:last-of-type {
						border-bottom: none;
					}

					._communication-template-group-title {
						margin-bottom: 20px;
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 20px;

						._title {
							font-weight: bold;
						}

						._add-template-button {
							display: flex;
							flex-direction: row;
							align-items: center;
							gap: 10px;
							border-radius: 15px;
							width: fit-content;
							padding: 5px 10px;
							background-color: rgba(120, 188, 97, 0.3);

							&:hover {
								cursor: pointer;
								background-color: rgba(120, 188, 97, 0.5);
							}

							ion-icon {
								font-size: 20px;
							}

							p {
								margin: 0;
								font-size: 13px;
							}
						}
					}

					._communication-message-type {
						margin-bottom: 40px;

						&:last-of-type {
							margin-bottom: 0;
						}

						._message-type-title {
							font-size: 15px;
						}

						._communication-template-container {
							display: flex;
							flex-direction: row;
							align-items: center;
							gap: 10px;
	
							._communication-template {
								display: flex;
								flex-direction: row;
								align-items: center;
								gap: 10px;
								background-color: #F1F1F1;
								border-radius: 15px;
								width: fit-content;
								padding: 5px 10px;

								&:hover {
									cursor: pointer;
								}

								&.email {
									background-color: rgba(207, 231, 232, 0.4);
									

									&:hover {
										background-color: rgba(207, 231, 232, 0.8);
									}
								}

								&.sms {
									background-color: rgba(255, 231, 201, 0.5);
									

									&:hover {
										background-color: rgba(255, 231, 201, 0.9);
									}
								}
	
								ion-icon {
									font-size: 20px;
								}
	
								p {
									margin: 0;
									font-size: 13px;
								}
							}
	
						}

					}

					

				}
		
				
			}


			._loan-contract-template-container {
				display: flex;
				flex-direction: column;
				overflow-y:auto;
				padding:20px;

				._editor-container {
					flex-grow: 10;

					editor-component {
						.ql-container {
							height:auto;

							.ql-editor{
								height:auto;
							}
						}
					}
			
				}
			}

			&._uit-database-container {
				padding: 20px;
				overflow: auto;

				._uit-database-logo {
					max-width: 500px;
					margin-bottom: 20px;
				}

				._content-container {
					max-width: 1300px;

					._selected-organizer-container {
						position: relative;
						background: #f1f1f1;
						padding: 15px;
						border-radius: 7px;
						border: 1px solid #d8d8d8;

						._selected-organizer {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							width: 100%;

							ion-icon {
								font-size: 20px;

								&:hover {
									cursor: pointer;
								}
							}
						}
					}

					._images-container {
						margin-bottom: 10px;

						._upload-container {
							background: #f1f1f1;
							border: 1px solid #d8d8d8;
							padding-bottom: 15px;
	
							._image-disclaimer {
								margin: 0;
								margin-bottom: 20px;
								font-weight: bold;
							}
						}
					}

					._uit-database-labels-container {
						margin-bottom: 10px;
						background: #f1f1f1;
						padding: 15px;
						padding-top: 0;
						border-radius: 7px;
						border: 1px solid #d8d8d8;

						._labels-container {
							display: flex;
							flex-direction: row;
							align-items: stretch;
							gap: 10px;
							flex-wrap: wrap;

							._label-container {
								background-color: white;
								border-radius: 7px;
								padding-left: 7px;
								width: fit-content;
								display: flex;
								flex-direction: row;
								vertical-align: center;
								align-items: center;

								._label-segment {
									padding: 5px 7px;

									&._label-action-container {
										border-left: 1px solid #d8d8d8;

										ion-icon {
											font-size: 18px;
											margin-top: 2px;
										}

										&:last-child {
											&:hover {
												cursor: pointer;
												background-color: #d8d8d8;
												border-radius: 0 7px 7px 0;
											}
										}

										&:hover {
											cursor: pointer;
											background-color: #d8d8d8;
										}
									}
								}

								&._create-label-container {
									background-color: $green;
									color: white;
									padding: 7px 14px;

									&:hover {
										cursor: pointer;
									}

									ion-icon {
										color: white;
										font-size: 20px;
									}

									span {
										margin-left: 5px;
									}

									&:hover {
										cursor: pointer;
									}
								}
							}
						}

						
					}


					._organizer-selection-options-container {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: stretch;

						._organizer-selection-option-container {
							width: 49.5%;

							search-organizer {
								display: block;
    							height: calc(100% - 50px);
							}

							._create-organisator-container {
								background: $light-green;
								padding:15px;
								border-radius:7px;
								border:1px solid #dde9d6;

								._data {
									margin-top: 10px;
								
									form-input ::ng-deep ._input input {
										background-color: white !important;
									}
								}
							}
						}
					}
				}

				
				
			}


			._buttons {
				width: 100%;
				display: flex;
				justify-content: flex-end;
				border-top: none;
			}			
		}
	}

}